<template>
  <v-card>
    <v-card-title>
      Excalibur Users
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      
    </v-card-title>

    <v-card-text>The data shown here is not in real time. Will be refreshed after every hour.</v-card-text>

    <v-data-table
      :headers="headers"
      :items="users"
      item-key="email"
      :search="search"
    >
      <template v-slot:[`item.status`]="{ item }">
        <label v-if="item.status === 'A'">Active</label>
        <label v-if="item.status === 'I'">Inactive</label>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/excalibur/user/${item._id}/profile` })">edit</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceExcalibur";
const service = new RestResource();
export default {
  data() {
    return {
      users: [],
      search: "",
      headers: [
        { text: "Email", value: "email", sortable: true },
        { text: "Phone", value: "phone", sortable: true },
        { text: "Respondent Id", value: "respondentId", sortable: true },
        { text: "Tracking Number", value: "trackingNumber", sortable: true },
         { text: "Delivery Status", value: "deliveryStatus", sortable: true },
         { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  methods: {
    getexcaliburUsers() {
      service.getexcaliburUsers().then(r => {
        this.users = r.data
        this.$disableLoader();
      });
    },
  },


  mounted() {
    this.$setLoader();
    this.getexcaliburUsers();
  }
  
};
</script>

<style scoped>
#bt1 {
  float: right;
}
</style>
